import _ from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cashPoolTopCurrencyAccountSelector, updateAccount } from 'reducers/cashPoolTopCurrencyAccount.slice';
import { cashPoolSelector, updateField } from 'reducers/cashPool.slice';
import { Box, Button, Modal, NumberInput, DateInput, RadioGroup, Text } from 'ui';
import { showToast, showErrorToast } from 'ui/components/Toast';
import { errorHandler } from 'utils/errors';
import { getParticipantsEstimatedRates } from 'api';
import { InTableParticipantType, ParticipantRatesType } from 'types/cashPool.types';
import { pricingApproachEnum } from 'enums';

type EstimateRatesModalType = {
  title?: string;
  buttonText?: string;
  modalWidth?: string;
  selectedParticipants: InTableParticipantType[];
  isFixed: boolean;
  isEstimateRatesModalShowing: boolean;
  onHide: () => void;
  currency: string;
};

type RequestDataType = {
  participants: any;
  issueDate?: Date;
  rateType: {
    type: 'fixed' | 'float';
  };
  creditRate: number;
  pricingApproach: string;
  cashPoolDebitRate: number;
  currency: string;
};

const EstimateRatesModal = ({
  title = 'Estimate Participants Rates',
  buttonText = 'Estimate',
  modalWidth = 'm',
  selectedParticipants,
  isFixed,
  isEstimateRatesModalShowing,
  onHide,
  currency = 'USD',
}: EstimateRatesModalType) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState<Date>();
  const [creditRate, setCreditRate] = useState<number>();
  const [creditRateError, setCreditRateError] = useState<string | null>(null);
  const [isEstimationInProgress, setIsEstimationInProgress] = useState(false);
  const [pricingApproach, setPricingApproach] = useState<string | null>(null);
  const rateOrSpread: string = isFixed ? 'rate' : 'spread';
  const { debitInterestRate: cashPoolDebitRate, creditInterestRate: cashPoolCreditRate } =
    useSelector(cashPoolSelector);
  const { debitInterestRate: topCurrencyAccountDebitRate } = useSelector(cashPoolTopCurrencyAccountSelector);

  const onSubmit = async () => {
    try {
      setCreditRateError(null);

      if (!creditRate || !pricingApproach) return showErrorToast();

      if (creditRate >= cashPoolCreditRate) {
        setCreditRateError(`Credit ${rateOrSpread} must be lower than the cash pool credit ${rateOrSpread}.`);
        return;
      }

      setIsEstimationInProgress(true);
      const data: RequestDataType = {
        participants: selectedParticipants.map((participant) =>
          _.pick(participant, ['id', 'parentCompanyId', 'name', 'country', 'industry', 'creditRating'])
        ),
        issueDate: date,
        rateType: { type: isFixed ? 'fixed' : 'float' },
        creditRate,
        pricingApproach,
        cashPoolDebitRate: cashPoolDebitRate ?? topCurrencyAccountDebitRate,
        currency,
      };
      for (const participant of data.participants) {
        if (participant.creditRating.rating == null) {
          throw new Error('Credit rating is required for all participants.');
        }
      }

      const estimateRatesCalculationLog: Record<string, any> = {};
      const estimatedRates: ParticipantRatesType[] = await getParticipantsEstimatedRates(data);

      for (const participant of selectedParticipants) {
        const estimatedRate = estimatedRates.find((p) => p.participantId === participant.id);
        if (!estimatedRate) return;

        const account = {
          creditInterestRate: estimatedRate.creditRate,
          debitInterestRate: estimatedRate.debitRate,
          companyId: participant.id,
          name: participant.name,
          currency: participant.currency,
        };
        estimateRatesCalculationLog[participant.name] = estimatedRate.calculationLog;
        dispatch(updateAccount(account));
        onHide();
      }

      dispatch(updateField({ estimateRatesCalculationLog }));
      showToast('Participant rates successfully estimated.');
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsEstimationInProgress(false);
    }
  };

  const pricingOptions = [
    {
      label: 'Implicit support adjusted rating',
      tooltip:
        'This pricing approach applies comparable data at the implicit support adjusted issuer ratings of the participants.',
      value: pricingApproachEnum.IMPLICIT,
    },
    {
      label: 'Stand-alone rating',
      tooltip: 'This pricing approach applies comparable data at the stand-alone issuer ratings of the participants.',
      value: pricingApproachEnum.STANDALONE,
    },
  ];

  const estimateRatesModalTooltips = {
    date: `Select the date for use in the estimation of participant standalone debit ${rateOrSpread}s.`,
    creditRate:
      `The standalone credit ${rateOrSpread} that is input will apply to all cash pool participants since credit ${rateOrSpread}s are less affected by borrower creditworthiness than debit ${rateOrSpread}s.<br/>` +
      `This cannot be more favorable than the credit ${rateOrSpread} received by the cash pool leader from the bank.`,
  };

  if (!isEstimateRatesModalShowing) return null;

  return (
    <Modal
      actionButtons={
        <Button
          disabled={Number.isNaN(creditRate) || creditRate == null || date == null || pricingApproach == null}
          text={buttonText}
          onClick={onSubmit}
          loading={isEstimationInProgress}
          iconRight="arrowRight"
        />
      }
      title={title}
      width={modalWidth}
      onHide={onHide}
    >
      <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <DateInput
          label="Date"
          maxDate={new Date()}
          tooltip={estimateRatesModalTooltips.date}
          value={date}
          width="fullWidth"
          onChange={setDate}
        />
        <NumberInput
          label={isFixed ? 'Credit Rate' : 'Credit Spread'}
          inputType="float"
          tooltip={estimateRatesModalTooltips.creditRate}
          allowNegatives={!isFixed}
          width="fullWidth"
          unit={isFixed ? '%' : 'bps'}
          value={creditRate}
          error={creditRateError}
          onChange={setCreditRate}
        />
      </Box>
      <Box sx={{ border: 'border', borderRadius: 'm', p: 4 }}>
        <Box sx={{ pb: 4 }}>
          <Text color="deep-sapphire">Select the pricing approach you would like to apply</Text>
        </Box>
        <Box>
          <RadioGroup
            value={pricingApproach}
            variant="column"
            options={pricingOptions}
            radioVariant="secondary"
            width="fullWidth"
            onChange={setPricingApproach}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default EstimateRatesModal;
