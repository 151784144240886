import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserInfoContext } from 'context/user';
import { Table, Button, FlexLayout } from 'ui';
import { PHYSICAL, NOTIONAL } from 'enums';
import { EstimateRatesModal, SeeAccountsDetailsModal } from 'components/Modals';
import { InTableParticipantType } from 'types/cashPool.types';
import { cashPoolSelector } from 'reducers/cashPool.slice';

import ExportImportButtons from './ExportImportButtons';
import { getCompaniesColumns, getCompaniesData, renderActionColumn } from '.';

type SelectParticipantsTableType = {
  setIsCirDirModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCurrencyModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBalanceModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsExternalIdsShowing: React.Dispatch<React.SetStateAction<boolean>>;
  accounts: any[];
  inTableParticipants: InTableParticipantType[];
  isFixed: boolean;
  type: string;
  companies: any[];
  limit: number;
  participantsOffset: number;
};

const SelectParticipantsTable = ({
  setIsCirDirModalShowing,
  setIsCurrencyModalShowing,
  setIsBalanceModalShowing,
  setIsExternalIdsShowing,
  accounts,
  inTableParticipants,
  isFixed,
  type,
  companies,
  limit,
  participantsOffset,
}: SelectParticipantsTableType) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo } = useContext(UserInfoContext);
  const { debitInterestRate, currencies } = useSelector(cashPoolSelector);

  const isNotional = type === NOTIONAL;
  const isPhysical = type === PHYSICAL;
  const [isEstimateRatesModalShowing, setIsEstimateRatesModalShowing] = useState(false);
  const [isDetailsModalShowing, setIsDetailsModalShowing] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState<InTableParticipantType[]>([]);
  const isEdit = history.location.pathname.includes('/edit');

  useEffect(() => {
    if (inTableParticipants) {
      setSelectedParticipants(inTableParticipants.filter((participant) => participant.value));
    }
  }, [inTableParticipants]);

  return (
    <>
      <Table
        actionColumn={(item: any) =>
          renderActionColumn({
            item,
            accounts,
            setIsCirDirModalShowing,
            setIsCurrencyModalShowing,
            setIsBalanceModalShowing,
            setIsExternalIdsShowing,
            isNotional,
            isFixed,
            isEdit,
            dispatch,
          })
        }
        columns={getCompaniesColumns({ dispatch, accounts, isFixed, isNotional })}
        data={getCompaniesData(inTableParticipants, isFixed, userInfo, accounts)}
        isSearchable
        rightTitleContent={
          <FlexLayout alignItems="center" space={6}>
            <Button variant="secondary" size="s" text="See details" onClick={() => setIsDetailsModalShowing(true)} />
            <Button
              variant="secondary"
              size="s"
              text="Estimate rates"
              isShowing={isPhysical}
              disabled={selectedParticipants.length === 0 || debitInterestRate == null}
              onClick={() => setIsEstimateRatesModalShowing(true)}
            />
            <ExportImportButtons inTableParticipants={inTableParticipants} companies={companies} isFixed={isFixed} />
          </FlexLayout>
        }
        customPageSize={limit}
        customPageIndex={participantsOffset}
        isPaginationHidden
      />
      <EstimateRatesModal
        onHide={() => setIsEstimateRatesModalShowing(false)}
        selectedParticipants={selectedParticipants}
        isFixed={isFixed}
        isEstimateRatesModalShowing={isEstimateRatesModalShowing}
        currency={currencies}
      />
      <SeeAccountsDetailsModal
        isDetailsModalShowing={isDetailsModalShowing}
        onHide={() => setIsDetailsModalShowing(false)}
      />
    </>
  );
};

export default React.memo(SelectParticipantsTable);
