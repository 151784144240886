import qs from 'query-string';

import { backend } from '~/services';
import { toFile } from '~/utils/documents';

export function getCashPool({ cashPoolId }) {
  return backend.get(`/cash-pool/${cashPoolId}`);
}

export function getCashPools() {
  return backend.get('/cash-pool');
}

export function getCashPoolParticipants({ cashPoolId }) {
  return backend.get(`/cash-pool/${cashPoolId}/participants`);
}

export function createPhysicalCashPool(data) {
  return backend.post('/cash-pool/physical', data);
}

export function createNordicCashPool(data) {
  return backend.post('/cash-pool/nordic', data);
}

export function createNotionalCashPool(data) {
  return backend.post('/cash-pool/notional', data);
}

export function deleteCashPool(id) {
  return backend.delete(`/cash-pool/${id}`);
}

export function updateCashPoolNote({ cashPoolId, data }) {
  return backend.patch(`/cash-pool/${cashPoolId}/note`, data);
}

export function updateEntirePhysicalCashPool({ cashPoolId, data }) {
  return backend.put(`/cash-pool/physical/${cashPoolId}`, data);
}

export function updateEntireNordicCashPool({ cashPoolId, data }) {
  return backend.put(`/cash-pool/nordic/${cashPoolId}`, data);
}

export function updateEntireNotionalCashPool({ cashPoolId, data }) {
  return backend.put(`/cash-pool/notional/${cashPoolId}`, data);
}

// post method to send data which contains filtering
export function getCashPoolParticipantTrails({ cashPoolId, data }) {
  return backend.post(`/cash-pool/${cashPoolId}/trail`, data);
}

export function getCashPoolStructuralPositions({ cashPoolId, data }) {
  return backend.post(`/cash-pool/${cashPoolId}/trail/structural-positions`, data);
}

export function getCashPoolParticipantTrailsBalanceDate({ cashPoolId, data }) {
  return backend.post(`/cash-pool/${cashPoolId}/trail/balance-date`, data);
}

export function getCashPoolTopCurrencyAccounts({ cashPoolId }) {
  return backend.get(`/cash-pool/${cashPoolId}/top-currency-account`);
}

export function getCashPoolBatchRange({ cashPoolId }) {
  return backend.get(`/cash-pool/${cashPoolId}/batch/range`);
}

export function getPaymentInterestDates({ cashPoolId }) {
  return backend.get(`/cash-pool/${cashPoolId}/batch/payment-interest-dates`);
}

export function makeCashPoolCalculations({ cashPoolId, cashPoolType, batchId }) {
  return backend.post(`/cash-pool/${cashPoolId}/batch/${batchId}/calculate-${cashPoolType.toLowerCase()}`);
}

export function getCashPoolPayments({ searchQuery = {}, limit = 10, offset = 0 }) {
  const queryString = qs.stringify({ limit, offset, ...searchQuery, reportType: 'cash pool' });
  return backend.get(`/cash-pool/CASH_POOL_ID_NOT_NEEDED/batch/BATCH_ID_NOT_NEEDED/payment/all?${queryString}`);
}

export async function exportCashPoolPaymentsAsExcel({ searchQuery = {} }) {
  const queryString = qs.stringify({ ...searchQuery });
  const response = await backend.get(
    `/cash-pool/CASH_POOL_ID_NOT_NEEDED/batch/BATCH_ID_NOT_NEEDED/payment/export-excel?${queryString}`,
    {
      fullResponse: true,
      responseType: 'blob',
    }
  );
  return toFile(response);
}

export function markCashPoolPaymentAsPaid({ cashPoolId, batchId, paymentId, data }) {
  return backend.patch(`/cash-pool/${cashPoolId}/batch/${batchId}/payment/${paymentId}`, data);
}

export function getCashPoolBatches({ cashPoolId }) {
  return backend.get(`/cash-pool/${cashPoolId}/batch`);
}

export async function getBatchFile({ cashPoolId, batchId }) {
  const response = await backend.get(`/cash-pool/${cashPoolId}/batch/${batchId}/file`, {
    fullResponse: true,
    responseType: 'blob',
  });
  return toFile(response);
}

export function deleteCashPoolBatch({ cashPoolId, batchId }) {
  return backend.delete(`/cash-pool/${cashPoolId}/batch/${batchId}`);
}

export function uploadBatchFile({ cashPoolId, file }) {
  const formData = new FormData();

  formData.append('file', file);

  return backend.post(`/cash-pool/${cashPoolId}/batch/file-upload`, formData);
}

export async function exportCashPoolAsExcel({ cashPoolId, type, data, exportType }) {
  const exportPath = `export-${type.toLowerCase()}`;

  const response = await backend.post(`/cash-pool/${cashPoolId}/${exportPath}?type=${exportType}`, data, {
    fullResponse: true,
    responseType: 'blob',
  });

  return toFile(response);
}

export function getCashPoolAuditTrailAll({ cashPoolId }) {
  return backend.get(`/cash-pool/${cashPoolId}/audit-trail`);
}

export function getCashPoolAuditTrail({ cashPoolId, auditTrailId }) {
  return backend.get(`/cash-pool/${cashPoolId}/audit-trail/${auditTrailId}`);
}

export function deleteCashPoolAuditTrail({ cashPoolId, auditTrailId }) {
  return backend.delete(`/cash-pool/${cashPoolId}/audit-trail/${auditTrailId}`);
}

export function getAuditTrailTopCurrencyAccountAll({ cashPoolId, auditTrailId }) {
  return backend.get(`/cash-pool/${cashPoolId}/audit-trail/${auditTrailId}/top-currency-account`);
}

export function getAuditTrailTopCurrencyAccount({ cashPoolId, auditTrailId, auditTrailTopCurrencyAccountId }) {
  return backend.get(
    `/cash-pool/${cashPoolId}/audit-trail/${auditTrailId}/top-currency-account/${auditTrailTopCurrencyAccountId}`
  );
}

export function getParticipantsEstimatedRates(data) {
  return backend.post('/cash-pool/estimate-participant-rates/', data);
}

export function createBatchFromSftpData({ cashPoolId, cashPoolType, data }) {
  return backend.post(`/cash-pool/${cashPoolId}/batch/create-from-sftp-data-${cashPoolType.toLowerCase()}`, data);
}

export function getCashPoolStatementData({
  cashPoolId,
  sort = 'date',
  dateRange,
  statementDate,
  source,
  isUsed,
  companyId,
  offset,
  limit,
}) {
  const queryString = qs.stringify({ offset, limit, sort, ...dateRange, statementDate, source, isUsed, companyId });
  return backend.get(`/cash-pool/${cashPoolId}/statement-data?${queryString}`);
}

export async function exportCashPoolStatementData({
  cashPoolId,
  sort = 'date',
  dateRange,
  statementDate,
  source,
  isUsed,
  companyId,
}) {
  const queryString = qs.stringify({ sort, ...dateRange, statementDate, source, isUsed, companyId });

  const response = await backend.get(`/cash-pool/${cashPoolId}/statement-data/export?${queryString}`, {
    fullResponse: true,
    responseType: 'blob',
  });
  return toFile(response);
}

export function createCashPoolStatementData({ cashPoolId, data }) {
  return backend.post(`/cash-pool/${cashPoolId}/statement-data`, data);
}

export function editCashPoolStatementData({ cashPoolId, data }) {
  const { statementId, ...rest } = data;
  return backend.put(`/cash-pool/${cashPoolId}/statement-data/${statementId}`, rest);
}

export function deleteCashPoolStatementData({ cashPoolId, statementDataId }) {
  return backend.delete(`/cash-pool/${cashPoolId}/statement-data/${statementDataId}`);
}
