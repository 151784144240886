import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

const initialState = {
  Payer: true,
  Recipient: true,
  'Loan type': true,
  Currency: true,
  'Interest amount': true,
  Status: true, // paid / unpaid
  'Interest number': true,

  'Rate type': false,
  'Issue date': false,
  'Maturity date': false,
  Rate: false,
  'Principal amount': false,
};

export const withholdingTaxPaymentColumnsSlice = createSlice({
  name: 'withholdingTaxPaymentColumns',
  initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateColumns: (_state, action) => {
      return action.payload;
    },
  },
});

export default withholdingTaxPaymentColumnsSlice.reducer;

export const { resetForm, setColumns, updateColumns } = withholdingTaxPaymentColumnsSlice.actions;

export const withholdingTaxPaymentColumns = (state: RootState) => state.withholdingTaxPaymentColumns;
