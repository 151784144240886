import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Guarantor: true,
  Principal: true,
  Currency: true,
  'Interest amount': true,
  'Interest due': true,
  Status: true, // paid / unpaid
  'Interest number': true,

  'Fee interest frequency': false,
  'Issue date': false,
  'Termination date': false,
  Rate: false,
  'Principal amount': false,
  'Guarantee Fee': false,
};

export const guaranteePaymentColumnsSlice = createSlice({
  name: 'guaranteePaymentColumns',
  initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateColumns: (_state, action) => {
      return action.payload;
    },
  },
});

export default guaranteePaymentColumnsSlice.reducer;

export const { resetForm, setColumns, updateColumns } = guaranteePaymentColumnsSlice.actions;

export const guaranteePaymentColumns = (state) => state.guaranteePaymentColumns;
